// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_patch_hash=qrbutyaqkfzukjhc5mxhmd4eha_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_patch_hash=qrbutyaqkfzukjhc5mxhmd4eha_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-grid-item {
  grid-area: attr(area);
}
`, "",{"version":3,"sources":["webpack://MusicHUB/Components/UI/Grid/GridItem.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB","sourcesContent":["app-grid-item {\r\n  grid-area: attr(area);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
