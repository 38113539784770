// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.8.1_patch_hash=qrbutyaqkfzukjhc5mxhmd4eha_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.8.1_patch_hash=qrbutyaqkfzukjhc5mxhmd4eha_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  user-select: none;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}
`, "",{"version":3,"sources":["webpack://MusicHUB/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;;EAEE,SAAS;EACT,UAAU;EACV,SAAS;EACT,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":["* {\r\n  user-select: none;\r\n  box-sizing: border-box;\r\n}\r\n\r\nhtml,\r\nbody {\r\n  margin: 0;\r\n  padding: 0;\r\n  border: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  /* overflow: hidden; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
